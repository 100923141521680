import React, { Fragment } from "react";
import { Typography, Stack } from "@mui/material";
import darkTheme from "@/src/theme";
import Head from "next/head";
import Image from 'next/image'
import LogoImage from '../public/images/logo.png';


const mainContainer = {
    height:'100vh',
    alignItems:'center',
    '& h1':{
      fontSize: 80,
      fontWeight: 400,
      color: darkTheme.palette.primary.main,
      fontFamily: `'Vina Sans', sans-serif`,
      letterSpacing: 3,
      mb:3
    },
    '& h6':{
      fontSize: 16,
      fontWeight: 400,
      fontFamily: `'Poppins', sans-serif`,
    },
}

export default function MyApp(props) {  

  return (
    <Fragment>
      <Head>
        <title>Weaverskart</title>
      </Head>
      <Stack display="flex" justifyContent="center" flexDirection="column" sx={mainContainer}>
        <Image src={LogoImage} alt="logo" width={200} height={175} />
        <Typography variant="h1">Coming Soon</Typography>
        <Typography variant="h5">we are under construction</Typography>
      </Stack>
    </Fragment>
  );
}
